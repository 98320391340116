/* eslint-disable no-restricted-globals */
import log from './logger';

const getConsent = (cmpName, vendorId) => {
  let consent;
  const __tcfapi = window.__tcfapi;
  switch (cmpName) {
    case 'didomi':
      consent = new Promise(resolve => {
        const w = parent.window;
        w.didomiOnReady = w.didomiOnReady || [];
        w.didomiOnReady.push(() => {
          consent = w.Didomi.getUserConsentStatusForVendor(vendorId);
          resolve(consent);
        });
      });
      break;
    case 'liveramp':
      consent = new Promise(resolve => {
        __tcfapi(
          'checkConsent',
          2,
          data => {
            resolve(data);
          },
          { data: [{ vendorId }] }
        );
      });
      break;
    case 'sourcepoint':
      consent = new Promise(resolve => {
        __tcfapi('getCustomVendorConsents', 2, data => {
          resolve(!!data.grants[vendorId].vendorGrant);
        });
      });
      break;
    // case 'quantcast':
    //   consent = new Promise(resolve => {
    //     __tcfapi('addEventListener', 2, () => {
    //       __tcfapi('getNonIABVendorConsents', 2, (data, success) => {
    //         if (success) resolve(data.nonIabVendorConsents[vendorId]);
    //       });
    //     });
    //   });
    //   break;
    case 'consentmanager':
      consent = new Promise(resolve => {
        window.__cmp(
          'addEventListener',
          [
            'consent',
            () => resolve(window.__cmp('getCMPData').vendorConsents[vendorId]),
            false,
          ],
          null
        );
      });
      break;
    default:
      consent = Promise.resolve(false);
      log(`unknown CMP, check settings`);
  }

  return consent;
};

export default getConsent;
