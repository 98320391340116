const createScript = src =>
  new Promise((resolve, reject) => {
    if (typeof document === 'undefined') {
      resolve();
    }

    const scriptTag = document.createElement('script');
    const node = document.getElementsByTagName('script')[0];
    scriptTag.src = src;
    scriptTag.onload = resolve;
    scriptTag.onerror = reject;
    scriptTag.async = true;
    node.parentNode.insertBefore(scriptTag, node);
  });

export default createScript;
