import Storage from './Storage';
import log from './logger';

const getCachedSegments = (k = []) => {
  let rawSegmentIds;
  let segmentIds = [];
  let keys;
  const list = PERSIST.segments;

  // if no argument, we use all the segment keys
  k.length ? (keys = list.filter(i => i.name.includes(k))) : (keys = list);

  keys.forEach(key => {
    rawSegmentIds = Storage.get(key.name, key.type);
    rawSegmentIds
      ? (segmentIds = segmentIds.concat(JSON.parse(rawSegmentIds)))
      : log(`segment key ${key.name} not found in ${key.type}`);
  });

  return segmentIds;
};

export default getCachedSegments;
