/* eslint-disable radix */
import createScript from './helpers/createScript';
import getValueFromObjectList from './helpers/getValueFromObjectList';
import getAllKeyValues from './helpers/getAllKeyValues';
import getLangIdFromUrl from './helpers/getLangIdFromUrl';
import getRegioFromCookie from './helpers/getRegioFromCookie';
import funnelEvents from './adapters/funnelEvents';
import intentEvents from './adapters/intentEvents';
import nativeArticleReads from './adapters/nativeArticleReads';
import Storage from './helpers/Storage';
import UserSync from './helpers/UserSync';
import clean from './helpers/clean';
import log from './helpers/logger';
import getConsent from './helpers/getConsent';
import apiAddExternalId from './helpers/apiAddExternalId';
import THEOplayersTracker from './adapters/THEOplayersTracker';
import sha256 from './helpers/sha256';

const init = e => {
  const cxjs = 'https://cdn.cxense.com/cx.js';
  const siteid = getLangIdFromUrl(CXENSE_SITE_ID);
  const prefix = PREFIX;
  const persist = PERSIST;
  const custom = { ...CUSTOM_CMP, ...CUSTOM_DMP };
  const eid = getValueFromObjectList(USER);
  const id = eid && eid.value ? clean(eid.value) : false;
  const cached = persist
    ? parseInt(Storage.get(persist.cache.name, persist.cache.type))
    : 0;

  /*
  0 new
  1 cached without eid
  2 cached with eid
  */

  // block all if we don't have consent
  const consent =
    custom.name && custom.vendor
      ? getConsent(custom.name, custom.vendor)
      : Promise.resolve(true);

  consent
    .then(c => {
      log(`consent ${c} for ${custom.vendor} in ${custom.name}`);
      if (c) {
        // initialize
        window.cX = window.cX || CXINIT;
        createScript(cxjs);
        window.cX.shouldPoll = false;
        window.cX.callQueue = window.cX.callQueue || [];

        // Single Page Application
        if (e) {
          window.cX.callQueue.push(['initializePage']);
        }

        // setSiteId
        window.cX.callQueue.push(['setSiteId', siteid]);

        if (USER) {
          // addExternalId
          if (id && cached !== 2) {
            window.cX.callQueue.push(['addExternalId', { id, type: prefix }]);
            if (custom.prefix && custom.queryid) {
              apiAddExternalId(
                id,
                custom.prefix,
                custom.queryid,
                !!custom.hash
              );
            }
          }
        }

        if (CUSTOM) {
          // setCustomParameters
          const cp = getAllKeyValues(CUSTOM);
          if (cp) {
            window.cX.callQueue.push(['setCustomParameters', cp]);
          }
        }

        // async actions which require the cX to be ready
        window.cX.callQueue.push([
          'invoke',
          () => {
            // start invoke
            // query string parameters to fetch from the URL
            if (URLARGS) {
              const urlArgs = window.cX.parseUrlArgs();
              const allowed = URLARGS;

              const cp = Object.keys(urlArgs)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                  obj[key] = urlArgs[key];
                  return obj;
                }, {});

              window.cX.setCustomParameters(cp);
            }
            // end invoke
          },
        ]);

        // next 3 adapters use a default pqid from MH
        if (MH_SHOP)
          // webshop events
          funnelEvents();

        if (INTENT)
          // intent events (Audience Interest Group)
          intentEvents();

        // deprecated after Nativo migration
        if (MH_ARTICLE_READ)
          // native article reads
          nativeArticleReads();

        if (MH_REGIO) {
          // regio box on popular MH titles
          const pc = getRegioFromCookie();
          if (pc) {
            window.cX.callQueue.push([
              'setUserProfileParameters',
              { regiobox: [pc] },
            ]);
          }
        }

        // stuff that needs to be stored so it can be reused in adserving
        if (PERSIST) {
          if (SYNC) {
            if (!cached) {
              const u = new UserSync(SYNC);
              u.sendRequests();
            }
          }

          window.cX.callQueue.push([
            'invoke',
            () => {
              // register userid in localStorage
              const uid = window.cX.getUserId();
              Storage.set(persist.user.name, uid, persist.user.type);
              persist.segments.forEach(segment => {
                // register segmentids in localStorage
                try {
                  window.cX.getUserSegmentIds(
                    {
                      persistedQueryId: segment.queryid,
                      callback: userSegmentIds => {
                        Storage.set(
                          segment.name,
                          JSON.stringify(userSegmentIds),
                          segment.type
                        );
                      },
                    },
                    id // if externalID
                      ? {
                          identities: [
                            {
                              id: segment.hash ? sha256(id) : id,
                              type: segment.prefix ? segment.prefix : prefix,
                            }, // overwrite default prefix?
                          ],
                        }
                      : {}
                  );
                } catch (err) {
                  log(`unable to get userSegmentIds from API`);
                  log(err);
                }
              });
            },
          ]);
          // use cache flag to perform some actions only once per session
          Storage.set(persist.cache.name, id ? 2 : 1, persist.cache.type);
        }

        // Send Page View Event
        if (window.location.pathname.match(FILTER)) {
          window.cX.callQueue.push([
            'sendPageViewEvent',
            {
              location: window.location.href,
              referrer: document.referrer,
              useAutoRefreshCheck: false,
            },
          ]);
        }
      }

      return false;
    })
    .catch(() => {
      // do nothing
    });
};

// run on content reload
if (TRIGGER) {
  const eventIn = TRIGGER.in;
  const eventOut = TRIGGER.out;

  // tell website this library is ready
  document.dispatchEvent(new CustomEvent(eventOut));
  log(`dispatching '${eventOut}' event`);
  // run this script as a pageview
  document.addEventListener(eventIn, init);
  log(`waiting for '${eventIn}' event to initialize`);
}

// run on every onload/refresh for non-SPA or hybrid SPA
if (!TRIGGER || TRIGGER.hybrid) {
  log(`init`);
  init(false);

  // SBS video events with Drupal info
  // outside of init(), don't want to run more then once
  if (SBS_VIDEO) {
    const p = new THEOplayersTracker();
    p.listenPlayerReady();
  }
}
